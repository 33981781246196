import React from "react"
import Layout from "../components/layout"
import Headline from "../components/headline"
import GridTwo from "../components/grid-2"

export const Companies = () => {
  React.useEffect(() => {
    if (window.location.hash) {
      // Fragment exists
      let elmnt = document.getElementById(window.location.hash.replace("#", ""))

      elmnt.scrollIntoView()
    } else {
      // Fragment doesn't exist
    }
  }, [])
  return (
    <Layout>
      <Headline
        title="Alliance World Group Companies"
        excerpt="The Alliance World group companies have a broad portfolio containing FMCG manufacturing, pharmaceuticals, agriculture & engineering tech."
      />

      <section
        className="section is-medium"
        style={{ borderBottom: "1px solid #ebebeb" }}
        id="alliance-india"
      >
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Alliance World Manufacturing Ltd.
          </h3>
          <br />
          <h5
            style={{ width: "80%" }}
            className="is-size-4 has-text-primary has-text-weight-light"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Alliance World Manufacturing Ltd. (f.k.a. Alliance India) is the
            FMCG arm of Alliance Group, organized into manufacturing customized
            products for leading multinationals. AWML is the No.1 and the
            largest TPM in India for bathing soaps.{" "}
          </h5>
          <br />
          <br />
          <GridTwo
            left="Over the years, Alliance World Manufacturing Ltd. has gained expertise in managing projects, validating and operating state of the art facilities that meet global standards. It caters to made-to-stock and made-to-order products and are organized into activities and processes to establish production networks while meeting regulatory compliances. <br><br>AWML has large and versatile plants at Baddi and Guwahati  capable of manufacturing and packaging soaps of various types, sizes and styles.<br /><br />Alliance World Manufacturing Ltd. complies to cGMP, HACCP, ISO 22716."
            right="<b>Alliance India Consumer Products Pvt. Ltd.</b> :  AICPPL is a 100% subsidiary of AWML and will be setting up   green field projects in strategic locations in India, to manufacture Consumer products like soaps and hair care products. These modern plants will produce some of the top brands in these segments. Integrated distribution centres will be another special feature at the facilities.<br><br><b>PT Alliance Consumer Products Indonesia</b> : Alliance Indonesia, is a subsidiary of AWML and has set up a state-of-the-art plant near Medan, Indonesia for manufacturing personal wash products like soaps for a global leader in the industry. The green field project spread over nine acres was completed in record time. The plant is strategically located to export its supplies to ASEAN, Japan, and Australian territories. It caters to the US markets as well."
          />
          <br />
          <br />
          <div className="columns is-variable is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-8"
            >
              <img
                src={"/companies/alliance-india.jpg"}
                alt=""
                style={{
                  width: "100%",
                  minHeight: "40vh",
                  background: "#ebebeb",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p className="is-size-5">
                Alliance World Manufacturing Ltd. clients include industry
                leaders like Unilever, Emami, Amazon and Cholayil.
              </p>
              <a
                href="https://allianceworldmanufacturing.com/"
                target="_blank"
                className="button is-primary is-outlined is-size-5 is-uppercase has-text-weight-light"
              >
                Go To Website
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section is-medium"
        style={{ borderBottom: "1px solid #ebebeb" }}
        id="alliance-formulations"
      >
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Alliance Formulations
          </h3>
          <br />
          <h5
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            style={{ width: "80%" }}
            className="is-size-4 has-text-primary has-text-weight-light"
          >
            Alliance Formulations is the pharmaceuticals wing of Alliance World,
            known for delivering world class products on specific timelines.{" "}
          </h5>
          <br />
          <br />
          <GridTwo
            left="Alliance Formulations has been associated with a wide range of pharma products from hand hygiene, surface disinfectant, antiseptic-alcohol based formulations, liquids, ointments, wound management and advanced sterility products."
            right="Alliance Formulation's pharmaceutical manufacturing units complies to all cGMP requirements and are also audited and certified for ISO 13485, ISO 9001 and by WHO for compliance to WHO GMP technical requirements."
          />
          <br />
          <br />
          <div className="columns is-variable is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-8"
            >
              <img
                src={"/companies/alliance-formulations.jpg"}
                alt=""
                style={{
                  width: "100%",
                  minHeight: "40vh",
                  background: "#ebebeb",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p className="is-size-5">
                Alliance Formulations clientele includes Schulke, Mayer, ITC Ltd
                & Lonza
              </p>
              <div>
                <a
                  href="https://allianceformulations.com"
                  target="_blank"
                  className="button is-primary is-outlined is-size-5 is-uppercase has-text-weight-light"
                >
                  Go To Website
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section is-medium"
        style={{ borderBottom: "1px solid #ebebeb" }}
        id="envair-electrodyne"
      >
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Envair Electrodyne Ltd.
          </h3>
          <br />
          <h5
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            style={{ width: "80%" }}
            className="is-size-4 has-text-primary has-text-weight-light"
          >
            Envair Electrodyne Ltd. formerly known as Kirloskar Electrodyne Ltd.
            Till 2002) was part of the multi-crore Kirloskar Group of Companies.
          </h5>
          <br />
          <br />
          <GridTwo
            left="Envair Electrodyne Limited powered by innovative and quality attitudes, state-of-the-art infrastructure, skilled work force and R&D facilities has indeed carved a solid niche for itself."
            right="In May 2018, the Alliance has acquired majority Equity Stake of the Company. Our mission is to enrich environment create world class quality products & systems in clean air & contamination control , technical furniture & oil cleaner."
          />
          <br />
          <br />
          <div className="columns is-variable is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-8"
            >
              <img
                src={"/companies/envair-electrodyne.jpg"}
                alt=""
                style={{
                  width: "100%",
                  minHeight: "40vh",
                  background: "#ebebeb",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p className="is-size-5"></p>
              <a
                href="http://www.envair.in/"
                target="_blank"
                className="button is-primary is-outlined is-size-5 is-uppercase has-text-weight-light"
              >
                Go To Website
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section is-medium"
        style={{ borderBottom: "1px solid #ebebeb" }}
        id="imperial-marketing-services"
      >
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Imperial Marketing Services
          </h3>
          <br />
          <h5
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            style={{ width: "80%" }}
            className="is-size-4 has-text-primary has-text-weight-light"
          >
            Imperial Marketing Services Pvt Ltd (IMS), an undertaking of
            Alliance World, is associated with formulating marketing strategies
            across business domains.
          </h5>
          <br />
          <br />
          <GridTwo
            left="Backed with a team of charged sales and marketing personnel, IMS specializes in analyzing marketing trends and formulating competitive strategies to outdo its competitors. At IMS, the team identifies the unmet needs and opportunities in the fast growing sectors, assesses the demand scenarios, reach out and tap identified customer segments. Also design services and products to deliver plans and set systems to collate marketing intelligence to continuously upgrade products with services, while tracking changes in the business environment. The logistics arm offers a full range of supply chain services that reduces cost and increases profit potential."
            right="At IMS, the comprehensive logistics services are integrated to meet customer requirements by providing innovation solutions tailored to the client's business. IMS proactively manages the supply chain to reduce cycle time, increase reliability and provide end-to-end solutions on measurable parameters."
          />
          <br />
          <br />
          <div className="columns is-variable is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-8"
            >
              <img
                src={"/companies/imperial-marketing-services.jpg"}
                alt=""
                style={{
                  width: "100%",
                  minHeight: "40vh",
                  background: "#ebebeb",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p className="is-size-5"></p>
              {/* <button className="button is-primary is-outlined is-size-5 is-uppercase has-text-weight-light">
                Go To Website
              </button> */}
            </div>
          </div>
        </div>
      </section>

      <section className="section is-medium" id="alliance-agri-tech">
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Alliance Agri-Tech
          </h3>
          <br />
          <h5
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            style={{ width: "80%" }}
            className="is-size-4 has-text-primary has-text-weight-light"
          >
            Alliance Agri-Tech
          </h5>
          <br />
          <br />
          <GridTwo
            left="Alliance have its registered office at Chandigarh, and our seed processing and storage facilities at Sonipat, Haryana. We setup one of the largest seed processing and seed storage infrastructure in Northern India, engaging professional talent required to build a sustainable & successful service provider for total seed solutions."
            right="Our facility includes a Processing plant, Cold storage, in house R&D capacities having research farms, green house and research laboratories and research collaboration with ICAR, State Agriculture Universities and MNC’s.<br/><br/>"
          />
          <br />
          <br />
          <div className="columns is-variable is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-8"
            >
              <img
                src={"/companies/alliance-agri-tech.jpg"}
                alt=""
                style={{
                  width: "100%",
                  minHeight: "40vh",
                  background: "#ebebeb",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p className="is-size-5">
                Our clients include RiceTech (Savannah Seeds), Syngenta, Mahyco
                etc.
              </p>
              <p className="is-size-5"></p>
              <a
                href="http://allianceagritech.com/"
                target="_blank"
                className="button is-primary is-outlined is-size-5 is-uppercase has-text-weight-light"
              >
                Go To Website
              </a>

              {/* <button className="is-disabled button is-primary is-outlined is-size-5 is-uppercase has-text-weight-light">
                Website Coming Soon
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Companies
